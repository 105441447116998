<template>
  <v-toolbar dense flat class="elevation-0 tool-bar-style">
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-select
        placeholder="Select font"
        aria-orientation="bottom"
        class="mr-4"
        hide-details
        :items="itemsFontsize"
        item-text="name"
        v-model="defaultData.fontSize"
        item-value="id"
        style="max-width: 120px;"
      >
      </v-select>
      <div v-if="isTextColor" class="position:relative mr-2">
        <v-btn
          :color="defaultData.fontColor"
          class="px-2"
          style="min-width: unset; border-width: 2px;"
          outlined
          @click="isShowSelectionColor = true"
        >
          <v-icon>mdi mdi-format-color-text</v-icon>
        </v-btn>
        <div class="sketch-color">
          <sketch-picker
            v-click-outside="onClickOutside"
            v-if="isShowSelectionColor"
            @input="updateValue"
            v-model="colors"
          />
        </div>
      </div>
      <v-btn-toggle @change="changeFontStyle" v-model="toggle_multiple" color="primary" dense group multiple>
        <v-btn :value="1" text>
          <v-icon>mdi-format-bold</v-icon>
        </v-btn>

        <v-btn :value="2" text>
          <v-icon>mdi-format-italic</v-icon>
        </v-btn>

        <v-btn :value="3" text>
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle v-if="isAlign" v-model="defaultData.align" color="primary" dense group mandatory>
        <v-btn :value="'left'" text>
          <v-icon>mdi-format-align-left</v-icon>
        </v-btn>

        <v-btn :value="'center'" text>
          <v-icon>mdi-format-align-center</v-icon>
        </v-btn>

        <v-btn :value="'right'" text>
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-toolbar>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'productPrice',
    },
    isAlign: {
      type: Boolean,
      default: true,
    },
    isTextColor: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      colorsText: '',
      isShowSelectionColor: false,
      colors: '#194d33',
      itemsFontsize: [],
      toggle_multiple: [],
      defaultData: {},
    };
  },

  watch: {
    toggle_multiple: {
      handler: function() {
        if (this.toggle_multiple.includes(1)) {
          this.defaultData.bold = true;
        } else this.defaultData.bold = false;
        if (this.toggle_multiple.includes(2)) {
          this.defaultData.italic = true;
        } else this.defaultData.italic = false;
        if (this.toggle_multiple.includes(3)) {
          this.defaultData.underline = true;
        } else this.defaultData.underline = false;
      },
      deep: true,
    },
    data: {
      handler: function() {
        // this.defaultData = Object.assign({}, this.data);
      },
      deep: true,
    },
    defaultData: {
      handler: function() {
        this.$emit('updataData', this.defaultData, this.type);
      },
      deep: true,
    },
  },
  created() {
    for (let i = 11; i < 40; i++) {
      this.itemsFontsize.push({ name: `${i}px`, id: `${i}px` });
    }
    this.defaultData = Object.assign({}, this.data);
    this.colors = this.defaultData.fontColor;
  },
  methods: {
    changeFontStyle() {
      // console.log('sdsd');
    },
    onClickOutside() {
      this.isShowSelectionColor = false;
    },
    updateValue() {
      //   this.isShowSelectionColor = false;
      this.defaultData.fontColor = this.colors.hex;
    },
  },
};
</script>

<style lang="scss">
.tool-bar-style {
  .v-toolbar__content {
    padding: 0px;
  }
  .sketch-color {
    position: absolute;
    top: -300px;
  }
  display: flex;
  align-items: center;
}
</style>

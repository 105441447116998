<template>
  <div style="min-height:310px">
    <div
      class="cart-item-demo pointer"
      @mouseover="isShowAction = true"
      @mouseleave="isShowAction = false"
      :elevation="1"
    >
      <div class="image__container">
        <img :src="productImg" />
      </div>
      <div :class="`text-${dataName.align} mt-3 `">
        <span
          :style="
            `color: ${dataName.fontColor}; font-size:${dataName.fontSize};` +
              (dataName.bold ? 'font-weight: bold;' : '') +
              (dataName.underline ? 'text-decoration: underline;' : '') +
              (dataName.italic ? ' font-style: italic;' : '')
          "
        >
          {{ dataName.textTitle }}
        </span>
      </div>
      <div :class="`text-${dataPrice.align} mt-3 `">
        <span
          :style="
            `color: ${dataPrice.fontColor}; font-size:${dataPrice.fontSize};` +
              (dataPrice.bold ? 'font-weight: bold;' : '') +
              (dataPrice.underline ? 'text-decoration: underline;' : '') +
              (dataPrice.italic ? ' font-style: italic;' : '')
          "
        >
          $29.00 </span
        ><del class="ml-2">$39.95</del>
      </div>
      <!-- <div class="price__container"><span class="text-color--main">$26.00</span> <del class="ml-2">$30.50</del></div> -->
      <transition v-if="isShowAction" name="fade">
        <div :class="`button__container ` + (isHover ? 'show-action' : '')">
          <div :class="`mt-3 button`">
            <span
              :style="
                ` font-size:${dataAction.callToAction.fontSize};` +
                  (dataAction.callToAction.bold ? 'font-weight: bold;' : '') +
                  (dataAction.callToAction.underline ? 'text-decoration: underline;' : '') +
                  (dataAction.callToAction.italic ? ' font-style: italic;' : '')
              "
            >
              Add to cart</span
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHover: {
      type: Boolean,
      default: false,
    },
    dataName: {
      type: Object,
      default: () => null,
    },
    dataPrice: {
      type: Object,
      default: () => null,
    },
    dataAction: {
      type: Object,
      default: () => null,
    },
    imgIdx: {
      type: Number,
      default: () => 0,
    },
  },
  computed: {
    productImg() {
      return this.imgList[this.imgIdx];
    },
  },
  data() {
    return {
      isShowAction: false,
      imgList: [
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c08a57187ad473b5ef710c.webp',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c08974187ad473b5ef546e.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c089fa187ad473b5ef66f7.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c08a3d187ad473b5ef6fe4.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c08a2c187ad473b5ef6e2d.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0e1ed187ad473b5f33b94.gif',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d768187ad473b5f1773d.webp',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d939187ad473b5f1af73.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d80b187ad473b5f189cd.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d813187ad473b5f18ac6.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d81f187ad473b5f18cab.png',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d828187ad473b5f18e99.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d83d187ad473b5f190cd.webp',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d876187ad473b5f19840.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d8a2187ad473b5f19eb2.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d7bc187ad473b5f17f42.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d7e0187ad473b5f183ec.jpg',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0d856187ad473b5f193df.webp',
        'https://minio.lattehub.com/img/320/0/resize/64bf49859b6b09000949df96/2023/07/26/latte-image-64c0e082187ad473b5f2e3fc.jpg',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// .show-action {
//   max-height: 0;
//   transition: max-height 0.15s ease-out;
//   overflow: hidden;
// }
// .cart-item-demo:hover .show-action {
//   max-height: 500px;
//   transition: max-height 0.25s ease-in;
// }
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.cart-item-demo {
  padding: 8px;
  min-width: 150px;
  // min-height: 350px;
  background: #fff;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 1px 0 0 rgb(42 48 56 / 4%);
  box-shadow: 0 1px 0 0 rgb(42 48 56 / 4%);
  border-radius: 3px;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0 12px 12px;
  .title-item-demo {
    text-align: left;
    color: rgb(96, 96, 96);
    font-size: 14px;
    font-weight: 800;
    // height: 42px;
  }
  .price__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
    margin-bottom: 12px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .button__container {
    display: flex;
    justify-content: center;
    .button {
      background: var(--v-primary-base);
      color: white;
      padding: 10px 20px;
    }
  }
  .image__container {
    width: calc(100% + 24px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: -12px;
    margin-bottom: 16px;
    img {
      max-width: 100%;
      // min-width: 198px;
      // height: 198px;
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"310px"}},[_c('div',{staticClass:"cart-item-demo pointer",attrs:{"elevation":1},on:{"mouseover":function($event){_vm.isShowAction = true},"mouseleave":function($event){_vm.isShowAction = false}}},[_c('div',{staticClass:"image__container"},[_c('img',{attrs:{"src":_vm.productImg}})]),_c('div',{class:("text-" + (_vm.dataName.align) + " mt-3 ")},[_c('span',{style:("color: " + (_vm.dataName.fontColor) + "; font-size:" + (_vm.dataName.fontSize) + ";" +
            (_vm.dataName.bold ? 'font-weight: bold;' : '') +
            (_vm.dataName.underline ? 'text-decoration: underline;' : '') +
            (_vm.dataName.italic ? ' font-style: italic;' : ''))},[_vm._v(" "+_vm._s(_vm.dataName.textTitle)+" ")])]),_c('div',{class:("text-" + (_vm.dataPrice.align) + " mt-3 ")},[_c('span',{style:("color: " + (_vm.dataPrice.fontColor) + "; font-size:" + (_vm.dataPrice.fontSize) + ";" +
            (_vm.dataPrice.bold ? 'font-weight: bold;' : '') +
            (_vm.dataPrice.underline ? 'text-decoration: underline;' : '') +
            (_vm.dataPrice.italic ? ' font-style: italic;' : ''))},[_vm._v(" $29.00 ")]),_c('del',{staticClass:"ml-2"},[_vm._v("$39.95")])]),(_vm.isShowAction)?_c('transition',{attrs:{"name":"fade"}},[_c('div',{class:"button__container " + (_vm.isHover ? 'show-action' : '')},[_c('div',{class:"mt-3 button"},[_c('span',{style:(" font-size:" + (_vm.dataAction.callToAction.fontSize) + ";" +
                (_vm.dataAction.callToAction.bold ? 'font-weight: bold;' : '') +
                (_vm.dataAction.callToAction.underline ? 'text-decoration: underline;' : '') +
                (_vm.dataAction.callToAction.italic ? ' font-style: italic;' : ''))},[_vm._v(" Add to cart")])])])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }